<template>
	<v-container fluid>
		<v-app-bar fixed color="background" elevation="0" class="py-lg-2 pr-lg-3 pr-1">
			<v-spacer></v-spacer>
			<v-btn color="accent" rounded :to="{name: 'IntroTrial'}">next</v-btn>
		</v-app-bar>
		<v-container>
			<v-row>
				<v-col cols="12" class="text-center pt-5">
					<h4 class="text-h4 text-center mb-5">welcome</h4>
				</v-col>
			</v-row>
			<v-row justify="center">
<!--				<v-col cols="auto" class="fill-height align-center">-->
<!--					<v-img src="../../assets/placeholder.png" max-width="150"></v-img>-->
<!--				</v-col>-->

				<v-col cols="12" sm="10" md="8" lg="6">
					<v-card color="primary" dark>
						<v-row align="center">
							<v-col cols>
								<v-card-title class="text-h5">rewind... <br>what's your story</v-card-title>
							</v-col>
							<v-col cols="auto">
								<v-img src="../../assets/placeholder.png" max-width="150"></v-img>
							</v-col>
						</v-row>

						<div class="d-flex flex-no-wrap justify-space-between align-center">
							<div>
								<v-card-text>
									<v-list color="transparent">
										<v-list-item class="mb-3 ps-0"><v-icon size="16" class="me-2" color="accent">mdi-circle</v-icon>some things we want to keep private</v-list-item>
										<v-list-item class="mb-3 ps-0"><v-icon size="16" class="me-2" color="accent">mdi-circle</v-icon>some things we only want to share with those closest to us</v-list-item>
										<v-list-item class="mb-3 ps-0"><v-icon size="16" class="me-2" color="accent">mdi-circle</v-icon>some things we want to remember forever</v-list-item>
									</v-list>
								</v-card-text>
							</div>
						</div>
						<v-card-title class="text-h6">rewind your entire life story with more than just photos.</v-card-title>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
export default {
	name: "Welcome",
	metaInfo: {
		title: 'Welcome'
	}
}
</script>